import React from "react"

 const IndexPage = () => (
  <div>
    <svg id="logo" width="561" height="117" viewBox="0 0 561 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.48 112C30.24 112 24.48 110.464 19.2 107.392C14.016 104.32 9.88801 100.144 6.81601 94.864C3.74401 89.584 2.20801 83.824 2.20801 77.584V62.032C2.20801 55.792 3.74401 50.032 6.81601 44.752C9.88801 39.472 14.016 35.296 19.2 32.224C24.48 29.152 30.24 27.616 36.48 27.616C42.72 27.616 48.432 29.152 53.616 32.224C58.896 35.296 63.072 39.472 66.144 44.752C69.216 50.032 70.752 55.792 70.752 62.032V71.68C70.752 73.408 70.128 74.896 68.88 76.144C67.728 77.296 66.288 77.872 64.56 77.872H19.488V78.592C19.488 83.296 21.12 87.328 24.384 90.688C27.744 93.952 31.776 95.584 36.48 95.584H59.376C60.24 95.584 60.96 95.872 61.536 96.448C62.112 97.024 62.4 97.744 62.4 98.608V108.976C62.4 109.84 62.112 110.56 61.536 111.136C60.96 111.712 60.24 112 59.376 112H36.48ZM53.472 62.32V61.024C53.472 56.32 51.792 52.336 48.432 49.072C45.168 45.712 41.184 44.032 36.48 44.032C31.776 44.032 27.744 45.712 24.384 49.072C21.12 52.336 19.488 56.32 19.488 61.024V62.32H53.472Z" stroke="#4A4A4A" stroke-width="3"/>
      <path d="M113.243 113.872C108.059 113.872 103.067 113.056 98.2673 111.424C93.4673 109.792 89.1953 107.536 85.4513 104.656C84.9713 104.272 84.6353 103.792 84.4433 103.216C84.3473 102.544 84.3953 101.92 84.5873 101.344C85.2593 99.808 86.8913 96.784 89.4833 92.272C90.2513 91.12 91.2113 90.544 92.3633 90.544C93.0353 90.544 93.7073 90.736 94.3793 91.12C97.8353 93.52 101.051 95.296 104.027 96.448C107.099 97.504 110.315 98.032 113.675 98.032C117.707 98.032 120.779 97.312 122.891 95.872C125.003 94.336 126.059 92.272 126.059 89.68C126.059 87.472 125.147 85.744 123.323 84.496C121.499 83.248 118.043 81.664 112.955 79.744L107.771 77.728C103.547 76.096 99.9473 74.368 96.9713 72.544C93.9953 70.624 91.4513 68.032 89.3393 64.768C87.3233 61.504 86.3153 57.424 86.3153 52.528C86.3153 44.944 88.8113 38.944 93.8033 34.528C98.8913 30.016 105.611 27.76 113.963 27.76C118.859 27.76 123.659 28.576 128.363 30.208C133.067 31.744 137.195 33.904 140.747 36.688C141.227 37.072 141.515 37.6 141.611 38.272C141.803 38.848 141.803 39.424 141.611 40C140.747 42.016 139.307 45.04 137.291 49.072C136.715 50.128 135.803 50.656 134.555 50.656C133.883 50.656 133.211 50.464 132.539 50.08C125.915 45.76 119.771 43.6 114.107 43.6C110.843 43.6 108.251 44.32 106.331 45.76C104.507 47.104 103.595 49.024 103.595 51.52C103.595 54.016 104.651 56.128 106.763 57.856C108.875 59.488 112.763 61.408 118.427 63.616C123.899 65.728 128.123 67.552 131.099 69.088C134.171 70.528 136.955 72.88 139.451 76.144C142.043 79.312 143.339 83.44 143.339 88.528C143.339 93.52 142.043 97.936 139.451 101.776C136.955 105.616 133.403 108.592 128.795 110.704C124.283 112.816 119.099 113.872 113.243 113.872Z" stroke="#4A4A4A" stroke-width="3"/>
      <path d="M192.028 112C184.54 112 178.444 109.696 173.74 105.088C169.132 100.48 166.828 94.432 166.828 86.944V45.616H156.892C156.028 45.616 155.308 45.328 154.732 44.752C154.156 44.176 153.868 43.456 153.868 42.592V32.512C153.868 31.648 154.156 30.928 154.732 30.352C155.308 29.776 156.028 29.488 156.892 29.488H166.828V13.936C166.828 13.072 167.116 12.352 167.692 11.776C168.268 11.2 168.988 10.912 169.852 10.912H181.084C181.948 10.912 182.668 11.2 183.244 11.776C183.82 12.352 184.108 13.072 184.108 13.936V29.488H208.444C209.308 29.488 210.028 29.776 210.604 30.352C211.18 30.928 211.468 31.648 211.468 32.512V42.736C211.468 43.6 211.18 44.32 210.604 44.896C210.028 45.472 209.308 45.76 208.444 45.76H184.108V86.224C184.108 89.104 184.924 91.408 186.556 93.136C188.284 94.864 190.54 95.728 193.324 95.728L208.444 95.872C209.308 95.872 210.028 96.16 210.604 96.736C211.18 97.312 211.468 98.032 211.468 98.896V108.976C211.468 109.84 211.18 110.56 210.604 111.136C210.028 111.712 209.308 112 208.444 112H192.028Z" stroke="#4A4A4A" stroke-width="3"/>
      <path d="M254.229 112.864C252.021 112.864 250.533 111.856 249.765 109.84L224.565 34.096C224.373 33.52 224.277 33.088 224.277 32.8C224.277 31.84 224.613 31.072 225.285 30.496C225.957 29.824 226.821 29.488 227.877 29.488H237.957C238.917 29.488 239.781 29.776 240.549 30.352C241.413 30.928 241.989 31.696 242.277 32.656L260.133 90.832L276.117 32.656C276.405 31.696 276.933 30.928 277.701 30.352C278.565 29.776 279.477 29.488 280.437 29.488H290.517C291.477 29.488 292.341 29.776 293.109 30.352C293.973 30.928 294.549 31.696 294.837 32.656L312.117 90.832L328.677 32.656C328.965 31.696 329.493 30.928 330.261 30.352C331.125 29.776 332.037 29.488 332.997 29.488H342.357C343.413 29.488 344.277 29.824 344.949 30.496C345.621 31.072 345.957 31.84 345.957 32.8C345.957 33.088 345.861 33.52 345.669 34.096L321.909 109.84C321.621 110.8 321.045 111.568 320.181 112.144C319.413 112.72 318.501 113.008 317.445 113.008L306.069 112.864C305.013 112.864 304.053 112.576 303.189 112C302.421 111.52 301.893 110.8 301.605 109.84L285.333 55.264L270.069 109.84C269.781 110.8 269.205 111.568 268.341 112.144C267.573 112.72 266.661 113.008 265.605 113.008L254.229 112.864Z" stroke="#4A4A4A" stroke-width="3"/>
      <path d="M364.037 112C363.173 112 362.453 111.712 361.877 111.136C361.301 110.56 361.013 109.84 361.013 108.976V32.512C361.013 31.648 361.301 30.928 361.877 30.352C362.453 29.776 363.173 29.488 364.037 29.488H375.269C376.133 29.488 376.853 29.776 377.429 30.352C378.005 30.928 378.293 31.648 378.293 32.512V39.28C380.981 35.632 384.149 32.8 387.797 30.784C391.541 28.672 395.621 27.616 400.037 27.616C405.509 27.616 410.405 28.912 414.725 31.504C419.141 34.096 422.597 37.696 425.093 42.304C427.589 46.912 428.837 52.096 428.837 57.856V108.976C428.837 109.84 428.549 110.56 427.973 111.136C427.397 111.712 426.677 112 425.813 112H414.581C413.717 112 412.997 111.712 412.421 111.136C411.845 110.56 411.557 109.84 411.557 108.976V60.016C411.557 54.928 410.261 51.04 407.669 48.352C405.077 45.568 401.429 44.176 396.725 44.176C391.061 44.176 386.549 46.048 383.189 49.792C379.925 53.44 378.293 58.48 378.293 64.912V108.976C378.293 109.84 378.005 110.56 377.429 111.136C376.853 111.712 376.133 112 375.269 112H364.037Z" stroke="#4A4A4A" stroke-width="3"/>
      <path d="M479.391 113.872C473.823 113.872 468.591 112.384 463.695 109.408C458.799 106.336 454.863 102.256 451.887 97.168C449.007 92.08 447.567 86.608 447.567 80.752V60.736C447.567 54.88 449.007 49.408 451.887 44.32C454.767 39.232 458.655 35.2 463.551 32.224C468.447 29.152 473.775 27.616 479.535 27.616C483.183 27.616 486.687 28.192 490.047 29.344C493.503 30.496 496.479 32.128 498.975 34.24V4.71998C498.975 3.85598 499.263 3.13599 499.839 2.55998C500.415 1.98398 501.135 1.69598 501.999 1.69598H513.231C514.095 1.69598 514.815 1.98398 515.391 2.55998C515.967 3.13599 516.255 3.85598 516.255 4.71998V108.976C516.255 109.84 515.967 110.56 515.391 111.136C514.815 111.712 514.095 112 513.231 112H503.871C503.007 112 502.287 111.712 501.711 111.136C501.135 110.56 500.847 109.84 500.847 108.976V102.064C498.255 106 495.135 108.976 491.487 110.992C487.839 112.912 483.807 113.872 479.391 113.872ZM481.839 97.312C486.543 97.312 490.575 95.68 493.935 92.416C497.295 89.056 498.975 85.024 498.975 80.32V61.168C498.975 56.464 497.295 52.48 493.935 49.216C490.575 45.856 486.543 44.176 481.839 44.176C477.135 44.176 473.103 45.856 469.743 49.216C466.479 52.48 464.847 56.464 464.847 61.168V80.32C464.847 85.024 466.479 89.056 469.743 92.416C473.103 95.68 477.135 97.312 481.839 97.312Z" stroke="#4A4A4A" stroke-width="3"/>
      <path d="M547.537 115.456C544.177 115.456 541.297 114.304 538.897 112C536.593 109.696 535.441 106.864 535.441 103.504C535.441 100.144 536.593 97.312 538.897 95.008C541.297 92.704 544.177 91.552 547.537 91.552C550.801 91.552 553.585 92.752 555.889 95.152C558.193 97.456 559.345 100.24 559.345 103.504C559.345 106.768 558.193 109.6 555.889 112C553.585 114.304 550.801 115.456 547.537 115.456Z" stroke="#4A4A4A" stroke-width="3"/>
    </svg>
  </div>
)

export default IndexPage